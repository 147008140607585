import 'swiper/css';
import 'swiper/css/pagination';
import './css/ma_main.css';

import Swiper, { Navigation, Pagination } from 'swiper';

var Isotope = require('isotope-layout');
import 'isotope-packery';


import imagesLoaded from 'imagesloaded';

function moreInfo(id) {
    var contname = "info-" + id;
    var cont = document.getElementById(contname);
    var status = cont.getAttribute("data-status");
    var buttonname = "info-button-" + id;
    var button = document.getElementById(buttonname);
    var text = button.querySelector(".info-button-text");
    var arrow = button.querySelector(".more-arrow");

    if (status === "c") {
        cont.classList.remove("hidden");
        cont.setAttribute("data-status", "o");
        text.innerHTML="Close info&nbsp;";
        arrow.classList.add("-rotate-180");
    } else {
        cont.classList.add("hidden");
        cont.setAttribute("data-status", "c");
        text.innerHTML="More info&nbsp;"
        arrow.classList.remove("-rotate-180");
    };

};

window.moreInfo = moreInfo;




//   ---------------------------------- Menu ----------------------------------

function toggleMenu() {

    var nav = document.getElementById("main-menu");
    var button = document.getElementById("menu-button");
    var status = nav.getAttribute("data-status");
  
    if (status === "c") {
      nav.classList.remove("translate-x-full");
      button.innerHTML = "Close";
      nav.setAttribute("data-status", "o");
      button.classList.add("text-black");
    } else if (status === "o") {
      nav.classList.add("translate-x-full");
      button.innerHTML = "Menu";
      nav.setAttribute("data-status", "c");
      button.classList.remove("text-black");
    };
  
  };
  
  window.toggleMenu = toggleMenu;




// ------------------------------  Swiper ------------------------------



Swiper.use([Navigation, Pagination]); 

var swipercont = document.getElementById("swiper-gallery");
if (typeof(swipercont) != 'undefined' && swipercont != null ) {
    var swiperlength = document.getElementById("swiper-gallery").getAttribute("data-loopnum");

    var swiper = new Swiper('.swiper-container', {
    loop: true,
    init: false,
    preloadImages: false,
    slidesPerView: 'auto',
    watchSlidesVisibility: true,
    loopedSlides: swiperlength,
        centeredSlides: false,
        spaceBetween: 64,
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    
    });

    // swiper.on('afterInit', function () {
    //     var currentslide = swiper.realIndex;
    //     var caption = swiper.slides[currentslide].getAttribute("data-caption");

    //     document.getElementById("gallery-caption").innerHTML = caption;

    //     document.getElementById("gallery-fullscreen-caption").innerHTML = caption;

    //     // console.log("Swiper init");
    //   });
    
    swiper.init();


    // swiper.on('slideChange', function () {
    //     var currentslide = swiper.realIndex;
    //     var caption = swiper.slides[currentslide].getAttribute("data-caption");
        
    //     document.getElementById("gallery-fullscreen-caption").innerHTML = caption;
    //     document.getElementById("gallery-caption").innerHTML = caption;
    //   });


      


} else false;



var isogridnews = document.getElementsByClassName("iso-grid-news");
    if ( isogridnews.length > 0 ) {

      var isonews = new Isotope( '.iso-grid-news', {
        itemSelector: '.grid-item',
        stamp: '.stamp',
        hiddenStyle: {
          opacity: 0
        },
        layoutMode: 'packery',
        percentPosition: true,
        // masonry: {
        //     columnWidth: '.grid-sizer',
        // },
        visibleStyle: {
          opacity: 1
        },
      });

      function showNewsItems () {
        var grid = document.getElementsByClassName("iso-grid-news");
        var i;
        for (i = 0; i < grid.length; i++) {
            grid[i].style.opacity = 1;
        };
        };

        var loadelem = document.getElementById("iso-grid-news");

        imagesLoaded( loadelem, function() { isonews.layout();showNewsItems(); } );


    } else false;

    var isogridresources = document.getElementsByClassName("iso-grid-resources");
    if ( isogridresources.length > 0 ) {

      var isoresources = new Isotope( '.iso-grid-resources', {
        itemSelector: '.grid-item',
        stamp: '.stamp',
        hiddenStyle: {
          opacity: 0
        },
        layoutMode: 'packery',
        percentPosition: true,
        // masonry: {
        //     columnWidth: '.grid-sizer',
        // },
        visibleStyle: {
          opacity: 1
        },
      });

      function showResourceItems () {
        var grid = document.getElementsByClassName("iso-grid-resources");
        var i;
        for (i = 0; i < grid.length; i++) {
            grid[i].style.opacity = 1;
        };
        };

        var loadelem = document.getElementById("iso-grid-resources");

        imagesLoaded( loadelem, function() { isoresources.layout();showResourceItems(); } );


    } else false;  


    // ------------------------------  Image Height Fix ------------------------------


function imageHeightFix() {

  if (CSS.supports("aspect-ratio: 1 / 1")) {


      // Should only need to add this to the 'else' part of the if statement. This is a temporary fix for a safari bug.

      var images = document.getElementsByClassName("lazyload");
      var i;
      for (i = 0; i < images.length; i++) {
          var imgwidth = images[i].getAttribute("width");
          var imgheight = images[i].getAttribute("height");



          if (images[i].parentElement.classList.contains("imagebackground")) {
              var containerwidth = images[i].parentElement.clientWidth;
              var newheight = (imgheight / imgwidth) * containerwidth;
              images[i].parentElement.style.height = newheight + 'px';
          } else {
              var containerwidth = images[i].clientWidth;
              var newheight = (imgheight / imgwidth) * containerwidth;
              images[i].style.height = newheight + 'px';
          };

          // console.log(newheight);

      };

      document.addEventListener('lazyloaded', function (e) {
          if (e.target.parentElement.classList.contains("imagebackground")) {
              e.target.parentElement.style.height = 'auto';
          } else {
              e.target.style.height = 'auto';
          };
      });

  } else {
      var images = document.getElementsByClassName("lazyload");
      var i;
      for (i = 0; i < images.length; i++) {
          var imgwidth = images[i].getAttribute("width");
          var imgheight = images[i].getAttribute("height");



          if (images[i].parentElement.classList.contains("imagebackground")) {
              var containerwidth = images[i].parentElement.clientWidth;
              var newheight = (imgheight / imgwidth) * containerwidth;
              images[i].parentElement.style.height = newheight + 'px';
          } else {
              var containerwidth = images[i].clientWidth;
              var newheight = (imgheight / imgwidth) * containerwidth;
              images[i].style.height = newheight + 'px';
          };

          // console.log(newheight);

      };

      document.addEventListener('lazyloaded', function (e) {
          if (e.target.parentElement.classList.contains("imagebackground")) {
              e.target.parentElement.style.height = 'auto';
          } else {
              e.target.style.height = 'auto';
          };
      });
  };
};